import * as React from "react"
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import HeroServicio from "../components/heroServicio";
import ConceptosServicios from "../components/conceptosServicio";
import GaleriaServicio from "../components/galeriaServicio";
import Multimedia from "../components/multimedia";
import CatalogoServicio from "../components/catalogoServicio";
import CtaCotizador from  "../components/ctaCotizadorServicio";
import BtnWa from '../components/btnWa';

export default function Index({ data: { queryServicio, site } }) {
  
  const servicioData = queryServicio.edges[0].node;
  
  return (
      <Layout>
        <HelmetDatoCms 
          seo={servicioData.seoMetaTags}
          favicon={site.favicon}
        />
        <HeroServicio
          cover={servicioData.cover}
          titulo={servicioData.titulo}
          introduccion={servicioData.introduccion}
          servicio='paneles-solares'
        />
        <ConceptosServicios
          img1={servicioData.img1}
          titulo1={servicioData.titulo1}
          desc1={servicioData.desc1}
          img2={servicioData.img2}
          titulo2={servicioData.titulo2}
          desc2={servicioData.desc2}
          img3={servicioData.img3}
          titulo3={servicioData.titulo3}
          desc3={servicioData.desc3}
          img4={servicioData.img4}
          titulo4={servicioData.titulo4}
          desc4={servicioData.desc4}
          img5={servicioData.img5}
          titulo5={servicioData.titulo5}
          desc5={servicioData.desc5}
          img6={servicioData.img6}
          titulo6={servicioData.titulo6}
          desc6={servicioData.desc6}
          img7={servicioData.img7}
          titulo7={servicioData.titulo7}
          desc7={servicioData.desc7}                     
        />
        <GaleriaServicio
          titulogaleria={servicioData.titulogaleria}
          galeria={servicioData.galeria}
        />
        <Multimedia
          video1={servicioData.video1}
          video2={servicioData.video2}
          video3={servicioData.video3}
        />        
        <CatalogoServicio
          catalogos={servicioData.documentos}
        />
        <CtaCotizador
            cta={servicioData.ctaservicio}
            servicio='paneles-solares'
        />
        <BtnWa/>
      </Layout>
      
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    queryServicio: allDatoCmsServicio(filter: {slug: {eq: "paneles-solares"}}) {
      edges {
        node {
          cover{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo {
            value
          }
          introduccion{
            value
          }
          img1{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo1{
            value
          }
          desc1{
            value
          }
          img2{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo2{
            value
          }
          desc2{
            value
          }
          img3{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo3{
            value
          }
          desc3{
            value
          } 
          img4{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo4{
            value
          }
          desc4{
            value
          }    
          img4{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo4{
            value
          }
          desc4{
            value
          } 
          img5{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo5{
            value
          }
          desc5{
            value
          }   
          img6{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo6{
            value
          }
          desc6{
            value
          }    
          img7{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 200px) 100vw, 200px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          titulo7{
            value
          }
          desc7{
            value
          }   
          titulogaleria{
            value
          }   
          galeria{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                title
                alt
          } 
          video1{
            url
          }
          video2{
            url
          }          
          video3{
            url
          }          
          documentos{
              url
              title
              alt
          }  
          ctaservicio{
            value
          }                                       
            
          seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
        }
      }
    }

  }
`;